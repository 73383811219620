// outer sources
import { useState } from "react";
import { ToastContainer } from "react-toastify";
import ReactGA from "react-ga4";

// components
import { AppBar } from "components/AppBar/AppBar";

// styles
import "../App.css";
import { PageRouter } from "../router/PageRouter";
import { AppProvider } from "@toolpad/core/AppProvider";
import { getSession, signOut } from "services/auth";
import { createTheme, CssBaseline } from "@mui/material";

export const App = () => {
  const [session, setSession] = useState(getSession());

  if (process.env.REACT_APP_GA_ID) {
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
  }

  const authentication = {
    signIn: () => {},
    signOut: () => {
      signOut();
      setSession(null);
    },
  };

  const lightTheme = createTheme({
    palette: {
      mode: "light",
    },
  });

  return (
    <div className="App">
      <AppProvider
        session={session}
        authentication={authentication}
        theme={lightTheme}
      >
        <CssBaseline />
        <AppBar />

        <PageRouter
          onSignIn={() => setSession(getSession())}
          onSignOut={() => authentication.signOut()}
        />

        <ToastContainer />
      </AppProvider>
    </div>
  );
};
