// components
import { LogoAPR } from "components/LogoAPR/LogoAPR";
import { AccountMenu } from "components/AccountMenu/AccountMenu";

// styled-components
import { HeaderCss, HeaderInnerContainerCss } from "./AppBar.styled";

export const AppBar = () => {
  return (
    <HeaderCss>
      <HeaderInnerContainerCss>
        <LogoAPR />

        <AccountMenu />
      </HeaderInnerContainerCss>
    </HeaderCss>
  );
};
