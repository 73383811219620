// import { NavLink } from "react-router-dom";

import LogoDesktop from "images/logo/logo-desktop-150x60.svg";

// styled-components
import { ImgCss } from "./LogoAPR.styled";

export const LogoAPR = () => {
  return <ImgCss src={LogoDesktop} alt="APR logo" />;
};
