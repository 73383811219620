import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { Home } from "../pages/Home/Home";
import { Login } from "pages/Login/Login";
import { Register } from "pages/Register/Register";
import { Invite } from "pages/Invite/Invite";
import { AcceptInvite } from "pages/AcceptInvite/AcceptInvite";
import ProtectedRoute from "./ProtectedRoute";
import { Projects } from "pages/Projects/Projects";
import { ResponseInterceptor } from "./ResponseInterceptor";

export const PageRouter = ({ onSignIn, onSignOut }) => {
  return (
    <Router>
      <Routes>
        <Route exact path="/login" element={<Login onSignIn={onSignIn} />} />
        <Route
          path="/invite/:token"
          element={<Invite onSignOut={onSignOut} />}
        />
        <Route
          exact
          path="/register"
          element={<Register onSignIn={onSignIn} />}
        />

        <Route element={<ProtectedRoute />}>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/invite/accept" element={<AcceptInvite />} />
          <Route path="/projects/:projectId?" element={<Projects />} />
        </Route>
      </Routes>
      <ResponseInterceptor />
    </Router>
  );
};
