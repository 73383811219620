import { useEffect } from "react";
import { Navigate, useParams } from "react-router";

export const Invite = ({ onSignOut }) => {
  let { token } = useParams();
  
  useEffect(() => {
    localStorage.setItem("invitation_token", token);
    onSignOut();
  }, [token, onSignOut]);

  return <Navigate to="/login" />;
};
