import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const DropzoneBoxCss = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95%;
  min-height: 60px;
  margin: 30px auto;
  border: 1px dotted #818181;
  border-radius: 5px;

  color: #515151;
  font-size: 20px;
  font-weight: 600;
  transition:
    background 300ms,
    color 1300ms;

  :hover {
    background-color: #818181;
    color: white;
  }
`;
