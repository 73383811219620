// outer sources
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// services
import { register } from "services/apiService";
import { toastOptions } from "../../services/toast-options";

// mui
import {
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  Tooltip,
  Button,
  IconButton,
  TextField,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// styled components
import {
  RegisterContainerCss,
  RegisterFormCardCss,
  TitleCss,
  RegisterFormCss,
} from "./Register.styled";

export const Register = ({ onSignIn }) => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState();
  const [name, setName] = useState();
  const [password, setPassword] = useState();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const result = await register(name, password);
      if (result.token !== undefined) {
        localStorage.setItem("token", result.token);
      }
      onSignIn();
      toast.success("Registration was completed successfully.", toastOptions);
      navigate("/invite/accept");
    } catch (error) {
      toast.error(
        "Something went wrong. Please try again later.",
        toastOptions
      );
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("invitation_token");
    if (token === null) {
      navigate("/login");
    } else {
      try {
        const decoded = jwtDecode(token);
        setEmail(decoded.email);
      } catch (error) {
        navigate("/login");
      }
    }
  }, [navigate, email]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>Appraisal Photo Report | Register</title>
      </Helmet>
      <RegisterContainerCss>
        <RegisterFormCardCss>
          <TitleCss component="h1" variant="h4" className="visually-hidden">
            Registration Form
          </TitleCss>

          <RegisterFormCss component="form" onSubmit={handleSubmit}>
            <TextField
              id="standard-helperText"
              label="Email"
              value={email || ""}
              variant="standard"
              helperText="Mail from the invitation letter"
              disabled
            />

            <FormControl sx={{ width: "100%" }} variant="standard">
              <InputLabel sx={{ paddingTop: "10px" }} htmlFor="name">
                Name
              </InputLabel>
              <Input
                sx={{ height: "45px" }}
                id="name"
                name="name"
                required={true}
                onChange={(e) => setName(e.target.value)}
              />
            </FormControl>

            <FormControl sx={{ width: "100%" }} variant="standard">
              <InputLabel sx={{ paddingTop: "10px" }} htmlFor="password">
                Password
              </InputLabel>
              <Input
                sx={{ height: "45px" }}
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                autoComplete="off"
                required={true}
                onChange={(e) => setPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <Tooltip
                      title={
                        showPassword
                          ? "Hide the password"
                          : "Display the password"
                      }
                      placement="top"
                    >
                      <IconButton onClick={handleClickShowPassword}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                }
              />
            </FormControl>

            <Button type="submit" fullWidth variant="contained">
              Register
            </Button>
          </RegisterFormCss>
        </RegisterFormCardCss>
      </RegisterContainerCss>
    </HelmetProvider>
  );
};
