// components
import { ImgNumberShow } from "components/ImgNumberShow";
import { ImgNumberPosition } from "components/ImgNumberPosition";

// styled-components
import {
  ImgNumberControlsBoxCss,
  ButtonGroupCss,
} from "./ImgNumberControls.styled";

export const ImgNumberControls = () => {
  return (
    <ImgNumberControlsBoxCss>
      <ButtonGroupCss
        variant="contained"
        type={"button"}
        aria-label="Image number controls button group"
      >
        <ImgNumberPosition />

        <ImgNumberShow />
      </ButtonGroupCss>
    </ImgNumberControlsBoxCss>
  );
};
