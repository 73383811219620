import styled from "@emotion/styled";
import { Stack, Card, Typography, Box } from "@mui/material";

export const LoginContainerCss = styled(Stack)`
  width: 100%;
  height: calc(100vh - 82px);
  margin-left: auto;
  margin-right: auto;

  background: #8e9eab; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    135deg,
    #8e9eab,
    #eef2f3
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    135deg,
    #8e9eab,
    #eef2f3
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
`;

export const LoginFormCardCss = styled(Card)`
  display: flex;
  flex-direction: column;
  min-width: 450px;
  margin: 20vh auto;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 2px;
`;

export const TitleCss = styled(Typography)``;

export const LoginFormCss = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  padding: 20px 40px 40px;
`;

export const WrapperRegisterBtnCss = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 2;
  width: 100%;
  padding: 20px 40px 40px;
`;
