import { Account } from "@toolpad/core/Account";

export const AccountMenu = () => {
  return (
    <Account
      slotProps={{
        signInButton: {
          sx: { display: "none" },
        },
      }}
    />
  );
};
