// components
import { TocSection } from "components/TocSection/TocSection";

// styled components
import { NavPanelBoxCss, ButtonGroupCss } from "./NavPanel.styled";

export const NavPanel = ({ folders, onUpdate }) => {
  return (
    <NavPanelBoxCss>
      <ButtonGroupCss
        variant="contained"
        type={"button"}
        aria-label="Navigation panel button group"
      >
        <TocSection folders={folders} onUpdate={onUpdate} />
      </ButtonGroupCss>
    </NavPanelBoxCss>
  );
};
