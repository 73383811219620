import { jwtDecode } from "jwt-decode";

export const getSession = () => {
  const token = localStorage.getItem("token");
  if (token === null) {
    return {};
  }

  try {
    const decoded = jwtDecode(token);

    let currentTimestamp = new Date().getTime() / 1000;
    if (decoded.exp < currentTimestamp) {
      return {};
    }

    return { user: { name: decoded.name, email: decoded.email } };
  } catch (error) {
    return {};
  }
};

export const signOut = () => {
  localStorage.removeItem("token");
};
