// outer sources
import { useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// services
import { login } from "services/apiService";
import { toastOptions } from "../../services/toast-options";

// mui
import {
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  Tooltip,
  Button,
  IconButton,
  Chip,
  Divider,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// styled components
import {
  LoginContainerCss,
  LoginFormCardCss,
  TitleCss,
  LoginFormCss,
  WrapperRegisterBtnCss,
} from "./Login.styled";

export const Login = ({ onSignIn }) => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    try {
      const result = await login(data.get("email"), data.get("password"));
      if (result.token !== undefined) {
        localStorage.setItem("token", result.token);
        onSignIn(result.token);
      }
      if (localStorage.getItem("invitation_token") !== null) {
        navigate("/invite/accept");
      } else {
        navigate("/");
      }
    } catch (error) {
      toast.error(
        "Something went wrong. Please try again later.",
        toastOptions
      );
    }
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>Appraisal Photo Report | Login</title>
      </Helmet>

      <LoginContainerCss>
        <LoginFormCardCss>
          <TitleCss component="h1" variant="h4" className="visually-hidden">
            Login Form
          </TitleCss>

          <LoginFormCss component="form" onSubmit={handleSubmit}>
            <FormControl sx={{ width: "100%" }} variant="standard">
              <InputLabel sx={{ paddingTop: "10px" }} htmlFor="email">
                Email
              </InputLabel>
              <Input
                sx={{ height: "45px" }}
                id="email"
                name="email"
                type="email"
                required={true}
              />
            </FormControl>

            <FormControl sx={{ width: "100%" }} variant="standard">
              <InputLabel sx={{ paddingTop: "10px" }} htmlFor="password">
                Password
              </InputLabel>
              <Input
                sx={{ height: "45px" }}
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                autoComplete="off"
                required={true}
                endAdornment={
                  <InputAdornment position="end">
                    <Tooltip
                      title={
                        showPassword
                          ? "Hide the password"
                          : "Display the password"
                      }
                      placement="top"
                    >
                      <IconButton onClick={handleClickShowPassword}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                }
              />
            </FormControl>

            <Button type="submit" fullWidth variant="contained">
              Login
            </Button>
          </LoginFormCss>

          {localStorage.getItem("invitation_token") != null && (
            <>
              <Divider>
                <Chip label="or" variant="outlined" />
              </Divider>
              <WrapperRegisterBtnCss>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={() => navigate("/register")}
                >
                  Register
                </Button>
              </WrapperRegisterBtnCss>
            </>
          )}
        </LoginFormCardCss>
      </LoginContainerCss>
    </HelmetProvider>
  );
};
