import styled from "@emotion/styled";
import { List, ListItem } from "@mui/material";

export const ControlBoardListCss = styled(List)`
  position: sticky;
  z-index: 999;
  top: 0;

  display: flex;
  margin: 10px 0;
  background-color: rgba(255, 255, 255, 0.9);
`;

export const ControlBoardListItemCss = styled(ListItem)`
  display: flex;
  justify-content: space-between;
  padding: 0 5px;
`;
