import styled from "@emotion/styled";
import { Container } from "@mui/material";

// constants
import { colors } from "constants";
const { primaryAccentColor } = colors;

export const HeaderCss = styled.header`
  width: 100%;
  top: 0;
  background: linear-gradient(135deg, #eef2f3, #eef2f3);
  border-bottom: 1px solid ${primaryAccentColor};
`;

export const HeaderInnerContainerCss = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
`;
